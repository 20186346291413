<template>
  <!-- Slider main container -->
  <div ref="swiper" class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->

      <template v-for="(image, index) in images">
        <div class="swiper-slide cards" style="width: 200px;" :key="index">
          <div class="card rounded-0 shadow">
            <div
              class="card-body shadow"
              :style="{
                backgroundImage: 'url(' + require('@/assets/' + image) + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }"
            >
              <!-- <h5 class="card-title" style="color: black;">
                Card title {{ index }}
              </h5>
              <p class="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p>
              <a href="#" class="btn btn-primary">Go somewhere</a> -->
            </div>
          </div>
        </div>
      </template>
      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>

      <!-- If we need navigation buttons -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>

      <!-- If we need scrollbar -->
      <div class="swiper-scrollbar"></div>
    </div>
  </div>
</template>
<script>
// import Vue from "vue";
// import LoadScript from "vue-plugin-load-script";
// Vue.use(LoadScript);

import Swiper from "../../node_modules/swiper/swiper-bundle";

/* eslint-disable */

export default {
  name: "Swiper",
  data() {
    return {
      images: [
        "mini_slider/img-endodontics.jpg",
        "mini_slider/img-periodontics.jpg",
        "mini_slider/img-radiology-and-lab.jpg",
        "mini_slider/img-oral-and-maxillofacial-surgery.jpg",
      ],
    };
  },
  methods: {
    init() {
      let swiperEl = this.$refs.swiper;
      const swiper = new Swiper(swiperEl, {
        // Optional parameters
        direction: "horizontal",
        loop: true,
        spaceBetween: 20,
        slidesPerView: "auto",
        slidesOffsetBefore: 200,

        // If we need pagination
        pagination: {
          el: ".swiper-pagination",
        },

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // And if we need scrollbar
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss">
@import "../../node_modules/swiper/swiper.scss";
.swiper {
  width: 100%;
  height: 100%;
  padding-top: 20px;

  .swiper-slide.cards {
    width: 100% !important;
    max-width: 300px !important;
    // height: 100% !important;
    height: 450px !important;

    .card-body {
      filter: blur(4px);
    }

    &.swiper-slide-active {
      .card-body {
        filter: blur(0);
      }
    }
  }
}
</style>
